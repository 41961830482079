<template>
  <list-template
      :loading="loading"
      :search-config="searchConfig"
      @onSearch="onSearch"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      @onAdd="$router.push('./add')"
      has-add
      :current-page="page"
      @onChangePage="handleCurrentChange"
      @onHandle="handleEdit"
  ></list-template>
</template>

<script>
//引入ajax接口管理层的接口函数
import {getRoleList2} from "@/api/role";
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed:{
    ...mapState(["page"])
  },
  methods: {
    onSearch(val){
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },


    async getData(searchVal) {
      let search = this.search;
      if(searchVal)search = searchVal;
      const name = search ? search.name : null;
      this.loading = true;
      let res = await getRoleList2(this.page, {name}).finally(()=>this.loading = false);
      //解构数据
      let { list:data, page } = res.data
      // 当前页码, 页数总和 , 每页数据量 , 总数据量
      let {total,last_page:pageCount} = page;
      this.total = total
      this.pageCount = pageCount

      //  赋值
      this.tableData = data
      this.tableData.map(item => {
        if (item.type == 1) {
          item.type = '学生'
        } else if (item.type == 2) {
          item.type = '教师'
        } else if (item.type == 3) {
          item.type = '校区子管理员'
        }
      })
    },
    //  点击编辑跳到编辑页面并把row这一行的数据作为参数传递。
    handleEdit(row,text,index) {
      const str = text.type
      switch (str) {
        case 'edit':
          this.$router.push({ path: './edit', query: { id: row.id } });
          break;
        case 'delete':
					this.$confirm("删除后，绑定此角色的账号将不可登录", {
            confirmButtonText: '删除',
            cancelButtonText: '点错了',
            type: 'warning'
          }).then(() => {
            this.$_axios2.post("/api/permission/delete-role",{ id: row.id, name: row.name },{logic:1})
                .then(res => {
                  this.getData()
                  this.$message({
                    type: 'success',
                    message: '删除成功'
                  })
                })
                .catch(() => {
                  this.$message({
                    type: 'warning',
                    message: '删除失败'
                  })
                })
          });
          break;
      }
    },
    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage",val);
      //拉取数据
      this.getData()
    },
    // 删除数据
    async handleDel(row) {

      // let res = await deleteRole({ id: row.id })
      // let { data, error } = res.data
      // let status = res.status

      // //判断code
      // if (status == 200) {
      // 	//删除成功弹框，“删除成功”
      // 	this.$message({
      // 		type: 'success',
      // 		message: '删除成功'
      // 	})
      // 	//重新发送请求从数据库中拉取数据渲染页面
      // 	this.getData()
      // } else {
      // 	this.$message.error(data)
      // }
    }
  },

  data() {
    return {
      loading:true,
      search:null,
      searchConfig:[
        {
          prop:"name",
          placeholder:"请输入角色名称"
        }
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig:[
        {
          prop:"name",
          label:"角色名称"
        },
        {
          prop:"level_name",
          label:"角色类型"
        },
        {
          prop:"is_school",
          label:"校区是否可见"
        },
        {
          prop:"created_at",
          label:"创建时间"
        },
        {
          prop:"handle",
          label:"操作",
          width:160,
          handle:true,
          // buttons:["edit","delete"],
        },
      ]
    }
  },
  activated() {
    this.getData()
  },
  created() {
    this.$store.commit("setPage",1);
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}
.button {
  // height: 100rem;
  //  padding-top: 30rem;
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  //background: rgba(24, 24, 120);
  opacity: 1;
  border-radius: 4rem;
  // display: flex;
  // .button-item {
  // width: 120rem;
  // font-size: 25rem;

  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // .additem{
  //   // font-size:20rem;
  //   display: flex;
  //   // justify-content: space-between;
  //   align-items: center;
  //   padding-left:3rem;
  //   //  word-spacing:10rem;
  //   .item{
  //     margin-left:10rem;
  //   }
  // }
}
// }
.table {
  margin-left: 32rem;
}


::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}
.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}
.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
